<template>
  <div class=""></div>
</template>

<script>
export default {
  name: "AppLayout",
  computed: {},
};
</script>

<style scoped></style>
